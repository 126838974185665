/**  =====================
      button css start
==========================  **/

.btn {
  border-radius: 5px;
}

.btn-page {
    .btn {
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .btn-group,
    .btn-group-vertical {
        .btn {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}

.btn.btn-icon {
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn-flat{

}

.btn-delete-danger {
    background: transparent;
    color: #9DA0A5;
    &:hover {
        color: #fff;
        background: #DA4157;
    }
}

.btn-edit-success {
    background: transparent;
    color: #9DA0A5;
    &:hover {
        color: #fff;
        background: #80C889;
    }
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active
 {
    color: #fff;
    border-color: #DA4157;
    background: #DA4157;
}

.btn-outline-secondary {
    color: #7D8188;
    border-color: #e3eaef;
}

.btn-success,
.btn-success:hover,
.btn-success:active,
{
  background-color: #80C889;
  border: #80C889;
}

.btn-primary-apps {
    padding: 10px 35px;
    background: $color-primary-bg !important;
    color: $color-primary !important;
    border: 1px solid transparent !important;
    font-weight: bold;
    margin: 0 10px;
}

.btn-secondary-apps {
    padding: 10px 35px;
    background: transparent !important;
    color: $color-muted !important;
    border: 1px solid $color-muted !important;
    font-weight: bold;
    margin: 0 10px;
}
