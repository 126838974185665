/**  =====================
      Badges css start
==========================  **/

.badge {
    font-size: 90%;
    padding: 7px 15px;
}
@each $color, $value in $theme-colors {
    .badge-light-#{$color} {
        background: transparentize($value,.8);
        border-color: theme-color-level($color, $alert-border-level);
        color: $value;
    }

    a.badge-light-#{$color} {
        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $value;
            border-color: $value;
            color: theme-color-level($color, $alert-bg-level);
        }
    }
}

.badge-success {
    color: #80C889;
    background: #E4FDE7;
}

.badge-warning {
    color: #D7A800;
    background: #FFF4CB;
}

.badge-danger {
    color: #DA4157;
    background: #FFE6E9;
}

/**====== Badges css end ======**/
