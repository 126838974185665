.successModal {
  .title {
    color: $color-black;
    font-weight: bold;
    font-size: 14px;
  }

  .desc {
    width: 80%;
    color: $color-muted;
    margin: 0 auto 25px;
  }

  // button {
  //   width: 60%;
  // }
}

.react-modal-body-close {
  display: none;
}
