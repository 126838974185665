.steps {
  &Container {
    background: #fff;
    border-right: 1px solid $color-border-grey;
    border-bottom: 1px solid $color-border-grey;
    border-left: 1px solid $color-border-grey;
    // box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 10px 10px;
  }

  .step-tab-container {
    border-bottom: 1px solid $color-border-grey;
  }

  &Button {
    padding: 10px 35px;
    background: $color-primary-bg !important;
    color: $color-primary !important;
    border: 1px solid transparent !important;
    font-weight: bold;
    margin: 0 10px;

    &:disabled {
      color:  #ffffff !important;
      font-size: 14px !important;
      background-color: rgba(128, 200, 137, 0.4) !important;
    }

    &.draft {
      border-color: $color-muted !important;
      background-color: transparent !important;
      color: $color-muted !important;
    }

    &.previous {
      background-color: transparent !important;
      border-color: transparent !important;
      margin-left: 0;
    }

    &.next {
      margin-right: 0;
    }

    // &:hover,
    // &:active,
    // &:focus, {
    //   background: $color-primary-bg;
    //   color: $color-primary;
    // }
  }

  &Progress {
    height: 10px;
    background: $color-border-grey;
    // border-radius: 5px;

    &-bar {
      background: $color-primary !important;
      height: 100%;
      // border-radius: 5px;
    }
  }

  .step-tab {
    color: $color-muted;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: $color-black;
    }

    &.active {
      color: $color-black;
    }
  }

  .form-group {
    > * {
      font-size: 14px;
      color: $color-black;
    }
  }

  .form-label {
    font-size: 12px;

    span {
      margin-left: 2px;
      color: $color-red-error;
    }
  }

  .form-text {
    font-size: 12px;

    &.error {
      color: $color-red-error;
    }
  }

  .custom-file {
    height: 39px;

    input {
      height: 100%;
    }
  }

  .input,
  textarea {
    padding: 8px;
    outline: none !important;
    box-shadow: none !important;
    width: 100%;
  }

  .form-control,
  .input,
  textarea,
  .custom-file-label {
    border: 1px solid $color-border-grey !important;
    padding-left: 8px;
    padding-right: 8px;

    // &:focus,
    // &:active {
    //   box-shadow: none !important;
    //   outline: none !important;
    // }

    &::placeholder {
      font-size: 12px;
      color: $color-muted;
    }
  }
}

.form-react-select {
  height: 38px !important;
  background-color: #ffffff !important;
  // font-family: $font-reguler !important;
  font-size: 14px !important;
  color: #333333 !important;

  // > * {
  //   z-index: auto;
  // }

  &__control {
    // border-radius: 5px !important;
    border-radius: 0 !important;
    border: solid 1px #c9cfdb !important;

    &--is-focused {
      // border-color: $color-primary;
      box-shadow: none !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__placeholder {
    color: $color-muted;
    font-size: 12px;
  }

  &__single-value {
    color: $color-black;
    font-size: 14px;
  }
}

.nav-tabs {
  background-color: transparent;
  border-bottom: 3px solid $color-border-grey;

  * {
    background-color: transparent !important;
    color: $color-black;
    font-weight: bold;
    position: relative;
    background: none !important;
    font-size: 16px;

    &::after {
      height: 3px;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      background-color: #4680ff;
      width: 0;
      transition: 0.3s ease-out;
    }

    &.active {
      // linear-gradient(to top, #4680ff 2px, rgba(255, 255, 255, 0) 2px)
      color: #4680ff !important;
      // background: none;

      &::after {
        width: 100%;
      }
    }
  }
}
