.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}

.btn-transparent {
  color: #212529;
  background-color: transparent;
  font-weight: 600;

  &:hover {
    background-color: #fff;
    border-radius: 4px;
  }
}

.btn-secondary {
  color: #7d8188;
  background-color: transparent;
  border-color: #d9dbdf;
  &:hover {
    background: #eaeaea;
    color: #7d8188;
    border-color: #d9dbdf;
  }
}
.btn-link-table {
  text-decoration: underline;
  color: #1E8FE1;
}

.uppercase {
  text-transform: uppercase;
}

.loader-component {
  align-items: center !important;
  display: flex !important;
  height: 72vh;
  justify-content: center !important;
}

.popover {
  padding: 15px;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  border-radius: 5px;
  border-color: #fff;
}

.text-align-end {
  text-align: end;
}

.border-right-corner-10 {
   border-bottom-right-radius: 10px; 
}

.border-left-corner-10 {
   border-bottom-left-radius: 10px; 
}

.m-l-minus-20 {
  margin-left: -20px;
}

.form-label {
  color: #37474f;
  font-weight: 900;
}

.background-illustration-subs {
  background-image: url("https://images.bareksa.com/priority/assets/pages/subscription.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  margin-left: -5px;
}

.success-color {
  color: #80C889;
}

.like-link {
  color: #4680ff;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
}
         
.error-otp {
  border: 1px solid rgb(218, 65, 87) !important;
} 

.order-list {
  padding: 10px 20px !important;
  &-line {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-bottom: 1px solid #d9dbdf;
  }
}

.text-c-grey {
  color: #868e96;
}

.text-dashboard-grey {
  color: #7D8188 !important;
}

.text-dashboard-grey {
  color: #7D8188 !important;
}

.text-black {
  color: $color-black !important;
}

.show-logo {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.show-logo-minimize {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

input:disabled {
  background-color: #e9ecef;
}
