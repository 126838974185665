.pagination {
//  padding: 20px;
  text-align: center;
  list-style: none;
}

.pagination__page {
  margin-left: 7px;
  display: inline-block;
}

.pagination__link {
  float: left;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  padding: 8px 14px;
  text-decoration: none;
  border: 1px solid #d9dbdf;
  &:hover, &:active, &:focus {
    background-color: #161616 !important;
    color: #fff !important;
  }
}

.pagination__link--active {
  background-color: #161616 !important;
  color: #fff !important;
}

