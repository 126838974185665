.react-select-big {
  height: 38px !important;
  background-color: #ffffff !important;
  // font-family: $font-reguler !important;
  font-size: 14px !important;
  color: #333333 !important;

  // > * {
  //   z-index: auto;
  // }

  &__control {
    // border-radius: 5px !important;
    border-radius: 4px !important;
    border: solid 1px #c9cfdb !important;

    &--is-focused {
      // border-color: $color-primary;
      box-shadow: none !important;
    }
  }

  &__value-container {
    padding: 6px 8px !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__placeholder {
    color: $color-muted;
    font-size: 14px;
  }

  &__single-value {
    color: $color-black;
    font-size: 14px;
  }
}

.subscriptions-list {
  padding: 10px 20px !important;
  &-line {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-bottom: 1px solid #d9dbdf;
  }
}
.otp-content-wrapper {
  justify-content: space-between;
  & > div {
    flex: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
