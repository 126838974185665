/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        margin: 0 auto;

        > .row {
            min-height: 100vh;

            > .aut-bg-img {
                min-height: 100vh;
            }
        }
    }

    .saprator {
        position: relative;
        margin: 8px 0;

        span {
            background: #fff;
            position: relative;
            padding: 0 10px;
            z-index: 5;
            font-size: 20px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $theme-border;
            z-index: 1;
        }
    }

    a {
        margin: 10px 0;
        text-decoration: underline;
    }

    .input-group {
        background: transparent;
    }

    .card {
        margin-bottom: 0;
        padding: 8px;

        .card-body {
            padding: 20px 25px 20px 40px;
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
    }

    > div {
        z-index: 5;
    }

    .auth-content {
        position: absolute;
        padding: 15px;
        z-index: 5;
        bottom: 0;
        left: 0;

        .dot {
            height: 12px;
            width: 12px;
            background-color: #80C889;
            border-radius: 50%;
            display: inline-block;
        }

        &:not(.container) {
            width: 400px;

            .card-body {
                padding: 40px 35px;
            }
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin: 0 auto;
        }
    }

    .auth-side-img {
        padding-right: 400px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }

    .auth-side-form {
        position: relative;
        min-height: 100%;
        background: rgb(22, 22, 22);
        display: block;
        align-items: center;
        left: auto;
        float: right;

        > * {
            position: relative;
            z-index: 5;
        }
        @media only screen and (max-width: 991px) {
            // width: 100%;
            // justify-content: center;

            .auth-content:not(.container) {
                max-width: 350px;
            }
        }
    }

    &.aut-bg-img {
        .auth-side-form {
            @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 100vh;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .card {
            .card-body {
                padding: 30px 15px;
            }
        }
    }

    &.offline {
        background-image: none;

        h1 {
            font-size: 87px;
            font-weight: 700;
        }

        &:before {
            display: none;
        }
    }

    .card-body .carousel-indicators {
        margin: 15px 0 10px;
        bottom: 0;

        li {
            width: 50px;
            background-color: transparentize($primary-color, 0.6);
            border-radius: 5px;
            height: 4px;

            &.active {
                background-color: $primary-color;
            }
        }
    }

    .img-logo-overlay {
        position: absolute;
        top: 40px;
        left: 50px;
    }
}
/* image varient start */
.aut-bg-img-reset {
    background-image: url("https://images.bareksa.com/priority/assets/reset_password.jpg");
}

.aut-bg-img-login {
    background-image: url("https://images.bareksa.com/priority/assets/login.jpg");
}

.aut-bg-img {
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    .auth-content {
        padding: 70px 40px;
    }
}
/* image varient End */
/**====== Authentication css end ======**/
