.p-notification {
  .ui-pnotify {
    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-closer,
    &-icon,
    &-text,
    &-title {
      margin: 0 !important;
      line-height: normal;
      padding: 0 !important;
    }

    &-closer {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
    }

    &-icon {
      font-size: 18px;
      margin-right: 10px !important;
    }
  }

  &-success {
    .ui-pnotify {
      &-container {
        background: #e4fde7 !important;
      }

      &-closer,
      &-icon,
      &-text,
      &-title {
        color: #35941e !important;
      }
    }
  }

  &-error {
    .ui-pnotify {
      &-container {
        background: $color-red-bg;
      }

      &-closer,
      &-icon,
      &-text,
      &-title {
        color: $color-red-text !important;
      }
    }
  }
}
