.DateRangePicker {
  width: 100%;
  border-radius: 4px;
}

.DateRangePickerInput__withBorder {
  border-radius: 4px;
}

.DateInput {
  border-radius: 4px;
}

.DateRangePickerInput {
  display: flex;
  width: 100%;
}

.DateInput_input {
  font-size: 13px;
  border-radius: 4px;
  padding: 6px 11px 6px;
}

.CalendarMonth_caption {
  font-size: 14px;
  padding-bottom: 30px;
}

.DateRangePickerInput_arrow {
  margin-top: 7px;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: transparent;
}

.DateRangePicker_override .DateRangePicker_picker {
  top: -132px !important;
  right: calc(100% + 20px) !important;
  left: unset !important;
}

.DayPicker_weekHeader {
  top: 50px;
}

.DayPicker_weekHeader_ul {
  font-size: 13px;
}

.CalendarDay {
  font-size: 12px;
}

.CalendarDay__selected, 
.CalendarDay__selected:active, 
.CalendarDay__selected:hover {
  background: #D7A800;
  border: 1px double #D7A800;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #FFF6D4;
  border: 1px double #e2d9b4;
  color: #484848;
}

.DateInput_input__focused {
  border-bottom: 2px solid #D7A800;
}

.CalendarDay__presets_button {
  position: relative !important;
  height: 100% !important;
  text-align: center !important;
  color: #D7A800 !important;
  margin-right: 8px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  background: none !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #D7A800 !important;
  border-image: initial !important;
  padding: 4px 12px !important;
  font: inherit inherit inherit inherit inherit inherit inherit inherit !important;
  overflow: visible !important;
  border-radius: 4px;
}

.CalendarDay__presets_button_active {
  background: #D7A800 !important;
  color: #fff !important;
}


.date-nav__years--month {
  display: flex;
  justify-content: center;
}

.select-years {
  background: transparent;
  border: 1px solid #e4e7e7;
  border-radius: 3px;
}

.select-months {
  background: transparent;
  border: 1px solid #e4e7e7;
  border-radius: 3px;
  margin-right: 5px;
}

.SingleDatePicker {
  display: block !important;
}

.SingleDatePickerInput {
  width: 100% !important;

  .DateInput {
    width: 100%;
  }
}

.SingleDatePicker_picker__directionLeft {
  top: -49px !important;
  right: calc(100% + 20px) !important;
  left: unset !important;
}

