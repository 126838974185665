.pcoded-navbar.menu-light .pcoded-inner-navbar .pcoded-submenu li > a.active{
  color: #ffffff;
}
body.able-pro-rtl .pcoded-navbar .pcoded-inner-navbar > li a .pcoded-micon{
  margin-left: 0 !important;
}
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li a.active{
  color: #ffffff;
  background: transparent;
}
body.able-pro-dark{
  .search-bar{
    background: lighten($dark-layout, 7%);
  }
}
.pcoded-header[class*='header-'] .btn-link{
  color:#fff;
}

@media only screen and (max-width: 991px){
  .pcoded-header[class*='header-'] .btn-link{
    color:#535763;
  }
}
.pcoded-navbar.menu-light .pcoded-inner-navbar > li > a.active{
  color: #ffffff;
}
// .pcoded-navbar.menu-light .pcoded-inner-navbar > li {
//   :hover {
//     a {
//       color: #ffffff;
//     }
//   }
// }

